<template>
    <Toast></Toast>
    <div class="grid">
        <div class="col-12">

            <div class="col-12 xl:col-12">
                <div class="card">
                    <h5 class="m-0">Validar Pagamentos</h5>
                </div>
            </div>

            <div class="card">
                <div class="grid">
                    <div class="col-12">
                        <div class="field m-0">
                            <label for="id_fornecedor">Filtrar por Fornecedor/Mês/Ano</label><br>
                            <Dropdown filter id="id_fornecedor" required="true" v-model="id_fornecedor"
                                :options="fornecedores" optionLabel="label" optionValue="value"
                                placeholder="Selecione um Fornecedor">
                            </Dropdown>

                            <Dropdown filter id="mes" required="true" v-model="mes" :options="meses" class="ml-3"
                                optionLabel="label" optionValue="value" placeholder="Selecione um Mês">
                            </Dropdown>

                            <Dropdown filter id="ano" required="true" v-model="ano" :options="anos" class="ml-3"
                                optionLabel="label" optionValue="value" placeholder="Selecione um Ano">
                            </Dropdown>

                            <Button class="ml-3" @click="listarRelatorioPorFornecedor()">
                                Filtrar
                            </Button>
                        </div>

                    </div>
                </div>
            </div>

            <div>
                <div class="card">
                    <div class="grid mb-2">
                        <div class="col-8">
                            <h5>Planilhas Importadas por Fornecedores</h5>
                        </div>
                        <div class="col-4" style="text-align: center;">
                            <Button :disabled="selecionados" class="ml-3 p-button-success"
                                @click="aprovarSelecionados()">
                                Aprovar Selecionados
                            </Button>
                            <Button :disabled="selecionados" class="ml-3 p-button-danger"
                                @click="reprovarSelecionados()">
                                Reprovar Selecionados
                            </Button>
                        </div>
                    </div>

                    <!-- Tabela principal -->
                    <DataTable v-model:filters="filters" :loading="loading" :value="listar_planilhas_importadas"
                        :rows="10" stripedRows scrollable scrollHeight="400px" :paginator="true"
                        responsiveLayout="scroll" v-model:selection="planilhasSelecionadas"
                        :globalFilterFields="['razao_social', 'usuario_responsavel', 'tipo']">

                        <!-- Cabeçalho de busca -->
                        <template #header>
                            <div class="flex justify-content-end">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search" />
                                    <InputText v-model="filters['global'].value" placeholder="Buscar" />
                                </span>
                            </div>
                        </template>

                        <!-- Coluna de seleção múltipla -->
                        <Column selectionMode="multiple" headerStyle="width: 3rem">
                        </Column>

                        <!-- Colunas exibindo dados do JSON -->
                        <Column field="razao_social" header="Fornecedor" :sortable="true" style="min-width: 250px">
                        </Column>

                        <Column field="tipo" header="Tipo" :sortable="true" style="min-width: 150px">
                        </Column>

                        <Column field="planilha_id" header="Planilha ID" :sortable="true" style="min-width: 150px">
                        </Column>

                        <Column field="lote_id" header="Lote" :sortable="true" style="min-width: 150px">
                        </Column>

                        <Column field="usuario_responsavel" header="Responsável" :sortable="true"
                            style="min-width: 200px">
                        </Column>

                        <Column field="valor_total" header="Valor Total (R$)" :sortable="true" style="min-width: 200px">
                        </Column>

                        <Column field="created_at" header="Data Criação" :sortable="true" style="min-width: 200px">
                        </Column>

                        <Column field="total_dados" header="Qtd. Dados" :sortable="true" style="min-width: 150px">
                        </Column>

                        <!-- Última Coluna: Botão Gerenciar -->
                        <Column frozen alignFrozen="right" style="white-space: nowrap;">
                            <template #body="slotProps">
                                <Button @click="mostrarPagamento(slotProps.data)" type="button"
                                    class="p-button-primary p-sm">
                                    Gerenciar
                                </Button>
                            </template>
                        </Column>
                    </DataTable>

                </div>
            </div>

        </div>
    </div>

    <!-- Modal / Dialog -->
    <Dialog v-model:visible="ModalPlanilhasFornecedores" :style="{ width: '80%' }"
        :header="fornecedor + ' - ' + mes_referencia" :modal="true">

        <div class="grid">
            <div class="col-12 lg:col-6 xl:col-2">
                <div class="card mb-0">
                    <div class="flex justify-content-between ">
                        <div>
                            <span class="block text-500 font-medium mb-3">Total End ID</span>
                            <div class="text-900 font-medium text-xl">{{ total_end_id }}</div>
                        </div>
                        <div class="flex align-items-center justify-content-center bg-blue-100 border-round"
                            style="width: 2.5rem; height: 2.5rem">
                            <i class="pi pi-cloud-upload text-blue-500 text-xl"></i>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 lg:col-6 xl:col-2">
                <div class="card mb-0">
                    <div class="flex justify-content-between ">
                        <div>
                            <span class="block text-500 font-medium mb-3">End ID Validado</span>
                            <div class="text-900 font-medium text-xl">{{ end_id_validado }}</div>
                        </div>
                        <div class="flex align-items-center justify-content-center bg-green-100 border-round"
                            style="width: 2.5rem; height: 2.5rem">
                            <i class="pi pi-check text-green-500 text-xl"></i>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 lg:col-6 xl:col-2">
                <div class="card mb-0">
                    <div class="flex justify-content-between ">
                        <div>
                            <span class="block text-500 font-medium mb-3">End ID Contestado</span>
                            <div class="text-900 font-medium text-xl">{{ end_id_contestado }}</div>
                        </div>
                        <div class="flex align-items-center justify-content-center bg-orange-100 border-round"
                            style="width: 2.5rem; height: 2.5rem">
                            <i class="pi pi-exclamation-triangle text-orange-500 text-xl"></i>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 lg:col-6 xl:col-2">
                <div class="card mb-0">
                    <div class="flex justify-content-between ">
                        <div>
                            <span class="block text-500 font-medium mb-3">Valor Solicitado</span>
                            <div class="text-900 font-medium text-xl">{{ valor_total }}</div>
                        </div>
                        <div class="flex align-items-center justify-content-center bg-blue-100 border-round"
                            style="width: 2.5rem; height: 2.5rem">
                            <i class="pi pi-dollar text-blue-500 text-xl"></i>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 lg:col-6 xl:col-2">
                <div class="card mb-0">
                    <div class="flex justify-content-between ">
                        <div>
                            <span class="block text-500 font-medium mb-3">Valor Contestado</span>
                            <div class="text-900 font-medium text-xl">{{ valor_contestado }}</div>
                        </div>
                        <div class="flex align-items-center justify-content-center bg-red-100 border-round"
                            style="width: 2.5rem; height: 2.5rem">
                            <i class="pi pi-dollar text-red-500 text-xl"></i>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 lg:col-6 xl:col-2">
                <div class="card mb-0">
                    <div class="flex justify-content-between ">
                        <div>
                            <span class="block text-500 font-medium mb-3">Valor Validado</span>
                            <div class="text-900 font-medium text-xl">{{ valor_validado }}</div>
                        </div>
                        <div class="flex align-items-center justify-content-center bg-green-100 border-round"
                            style="width: 2.5rem; height: 2.5rem">
                            <i class="pi pi-dollar text-green-500 text-xl"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <hr />

        <!-- Tabela de Motivos da Contestação -->
        <!-- Seção que exibe somente os motivos vindos da API -->
        <!-- Container com 'grid' UNA vez só -->
        <div class="card">
            <div class="grid">
                <!-- Cabeçalho -->
                <div class="col-8 bg-blue-100">
                    <b>Motivos da Contestação</b>
                </div>
                <div class="col-2 bg-blue-100">
                    <b>Total</b>
                </div>
                <div class="col-2 bg-blue-100">
                    <b>Valor Contestado (R$)</b>
                </div>

                <!-- Items (v-for) -->
                <div v-for="(item, idx) in motivosContestacao" :key="idx" class="col-12" style="padding: 0.3rem 0;">
                    <div class="grid">
                        <div class="col-8">{{ item.motivo_contestacao }}</div>
                        <div class="col-2">{{ item.total_registros }}</div>
                        <div class="col-2">{{ item.total_valor }}</div>
                    </div>
                </div>

                <!-- Linha final (Valor Total), sempre exibida -->
                <div class="col-12" style="margin-top: 0.5rem;">
                    <div class="grid">
                        <div class="col-8"> </div> <!-- col vazia para "empurrar" a linha -->
                        <div class="col-2 bg-blue-50 text-right">
                            <b>Total Geral</b>
                        </div>
                        <div class="col-2 text-right">
                            <b>{{ valor_contestado }}</b>
                        </div>
                    </div>
                </div>

            </div>
        </div>




        <hr />

        <div class="card" style="overflow: hidden;">
            <Button :disabled="temdados" :loading="loading" label="Aprovar Pagamento" @click="aprovarPagamento()"
                class="p-button-success" style="float: left;" />

            <Button :disabled="temdados" :loading="loading" label="Reprovar Pagamento" @click="reprovarPagamento()"
                class="p-button-danger" style="float: right;" />
        </div>
    </Dialog>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import axios from 'axios';

export default {
    data() {
        return {
            // ---------- Estado Principal ----------
            listar_planilhas_importadas: [],
            planilhasSelecionadas: null,
            ModalPlanilhasFornecedores: false,

            // ---------- Campos do Dialog ----------
            motivosContestacao: [],     // Array dinâmico de motivos
            total_end_id: 0,
            end_id_validado: 0,
            end_id_contestado: 0,
            valor_validado: 0,
            valor_contestado: 0,
            valor_total: 0,

            // Campos que podem ser 0 se não retornados
            medidor_proprio_tim: 0,
            total_medidor_proprio_tim: 0,
            site_em_aquisicao: 0,
            total_site_em_aquisicao: 0,
            outros: 0,
            total_outros: 0,
            valor_total_contestado: 0,

            // ---------- Outros ----------
            planilhas_por_fornecedores: [],
            gerenciar_planilhas_importadas: [],
            fornecedores: [],
            id_fornecedor: 0,
            mes: "",
            ano: "",
            temdados: true,
            loading: false,
            selecionados: true,

            // Exemplo para titulo do modal
            fornecedor: "TIM",
            mes_referencia: "JANEIRO",

            // Filtros DataTable
            filters: {},
            statuses: [
                { label: "Inativo", value: "0" },
                { label: "Ativo", value: "1" },
            ],

            // Exemplo de cabeçalho, se precisar
            cabecalho: [
                // ...
            ],
        };
    },

    created() {
        this.initFilters();
    },

    mounted() {
        this.listarFornecedor();
        this.listarPlanilhasImportadas();
    },

    watch: {
        planilhasSelecionadas(novoValor) {
            if (novoValor && novoValor.length > 0) {
                this.selecionados = false;
            } else {
                this.selecionados = true;
            }
        }
    },

    methods: {
        // ------------------------------------------------------------------
        // Filtros
        // ------------------------------------------------------------------
        initFilters() {
            this.filters = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            };
        },

        // ------------------------------------------------------------------
        // Carregar dados iniciais
        // ------------------------------------------------------------------
        listarFornecedor() {
            axios
                .post("/fornecedores/listar")
                .then((response) => {
                    let options = [];
                    response.data.dados.forEach((el) => {
                        options.push({ value: el["id"], label: el["razao_social"] });
                    });
                    this.fornecedores = options;
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        listarPlanilhasImportadas() {
            axios
                .post("/relatorios/planilhas-importadas")
                .then((response) => {
                    this.listar_planilhas_importadas = response.data.dados;
                })
                .catch(() => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Ops!",
                        detail: "Chamar o T.I.",
                        life: 3000,
                    });
                });
        },

        // ------------------------------------------------------------------
        // Filtrar por fornecedor/mês/ano (opcional)
        // ------------------------------------------------------------------
        listarRelatorioPorFornecedor() {
            let payload = {
                id_fornecedor: this.id_fornecedor,
                mes: this.mes,
                ano: this.ano
            };
            axios
                .post("/relatorios/planilhas-por-fornecedor", payload)
                .then((response) => {
                    this.planilhas_por_fornecedores = response.data.dados;
                })
                .catch(() => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Ops!",
                        detail: "Chamar o T.I.",
                        life: 3000,
                    });
                });
        },

        // ------------------------------------------------------------------
        // Botão "Gerenciar" na Tabela
        // ------------------------------------------------------------------
        mostrarPagamento(planilha) {
            // Se precisar chamar "listarRelatorio" (exemplo):
            //this.listarRelatorio(planilha.id, planilha.id_fornecedor);
            this.gerenciarPagamentosPlanilhasImportadas(planilha.planilha_id, planilha.tipo, planilha.id_fornecedor);

            // Ajusta dados para título do modal
            this.fornecedor = planilha.razao_social || "";
            this.mes_referencia = planilha.mes_referencia || "";

            // Zera/limpa campos do modal que podem ser sobrescritos pela API
            this.total_end_id = 0;
            this.end_id_validado = 0;
            this.end_id_contestado = 0;
            this.valor_validado = 0;
            this.valor_contestado = 0;
            this.valor_total = 0;
            this.motivosContestacao = [];

            this.medidor_proprio_tim = 0;
            this.total_medidor_proprio_tim = 0;
            this.site_em_aquisicao = 0;
            this.total_site_em_aquisicao = 0;
            this.outros = 0;
            this.total_outros = 0;
            this.valor_total_contestado = 0;

            // Chama a nova rota que retorna os motivos de contestação
            //this.gerenciarPagamentosPlanilhasImportadas(planilha.id);

            // Abre o Dialog
            this.ModalPlanilhasFornecedores = true;
        },

        // ------------------------------------------------------------------
        // Novo método para buscar motivos de contestação e etc.
        // ------------------------------------------------------------------
        gerenciarPagamentosPlanilhasImportadas(idPlanilha, tipo, id_fornecedor) {
        const payload = { id_planilha: idPlanilha, tipo: tipo, id_fornecedor: id_fornecedor };
        this.axios
            .post("/relatorios/gerenciar-planilhas-importadas", payload)
            .then((res) => {
                // Aqui você pega o primeiro objeto do array "dados" e faz a atribuição
                if (res.data && res.data.dados && res.data.dados[0]) {
                    const item = res.data.dados[0];
                    
                    // Atribuição conforme solicitado:
                    this.total_end_id = item.registros_planilha;                      // Total End ID
                    this.end_id_validado = item.registros_planilha_sem_contestacao;   // End ID Validado
                    this.end_id_contestado = item.registros_planilha_contestados;     // End ID Contestado
                    this.valor_total = item.valor_total_registros_importados;         // Valor Solicitado
                    this.valor_contestado = item.valor_total_contestado;              // Valor Contestado
                }

                // Se vier a lista de contestação, atribua também
                if (res.data && res.data.dadosContestacao) {
                    this.motivosContestacao = res.data.dadosContestacao;
                } else {
                    this.motivosContestacao = [];
                }
            })
        .catch(() => {
            this.$toast.add({
                severity: "error",
                summary: "Ops!",
                detail: "Chamar o T.I.",
                life: 3000,
            });
        });
},


        // ------------------------------------------------------------------
        // Ações de Aprovar/Reprovar (em massa ou individual)
        // ------------------------------------------------------------------
        aprovarSelecionados() {
            let objeto = this.planilhasSelecionadas || [];
            for (const prop in objeto) {
                this.aprovarPagamento(objeto[prop].id);
            }
            this.listarInicial();
        },
        reprovarSelecionados() {
            let objeto = this.planilhasSelecionadas || [];
            for (const prop in objeto) {
                this.aprovarPagamento(objeto[prop].id);
            }
            this.listarInicial();
        },

        aprovarPagamento(id) {
            this.loading = true;
            let dados = { id_planilha: id || this.id_planilha, status: 3 };
            axios
                .post("/fornecedores/aprovar-pagamento", dados)
                .then(() => {
                    this.listarRelatorioPorFornecedor();
                    this.loading = false;
                    this.ModalPlanilhasFornecedores = false;
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error);
                });
        },
        reprovarPagamento(id) {
            this.loading = true;
            let dados = { id_planilha: id || this.id_planilha, status: 1 };
            axios
                .post("/fornecedores/aprovar-pagamento", dados)
                .then(() => {
                    this.listarRelatorioPorFornecedor();
                    this.loading = false;
                    this.ModalPlanilhasFornecedores = false;
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error);
                });
        },

        // ------------------------------------------------------------------
        // Lista e Relatorio (conforme seu código atual)
        // ------------------------------------------------------------------
        listarInicial() {
            axios
                .post("/relatorios/planilhas-por-fornecedor")
                .then((response) => {
                    this.planilhas_por_fornecedores = response.data.dados;
                })
                .catch(() => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Ops!",
                        detail: "Chamar o T.I.",
                        life: 3000,
                    });
                });
        },
        listarRelatorio(id, id_fornecedor) {
            this.id_planilha = id;
            let dados = {
                id_fornecedor: id_fornecedor,
                mes: this.mes,
                ano: this.ano,
                id_planilha: id
            };
            axios
                .post("/relatorios/liberar-pagamentos", dados)
                .then((response) => {
                    if (response.data.dados.planilhas_validadas > 0) {
                        this.temdados = false;
                    } else {
                        this.temdados = true;
                    }

                    // Preenche contadores e valores
                    this.planilhas_importadas = response.data.dados.planilhas_importadas;
                    this.planilhas_validar = response.data.dados.planilhas_validar;
                    this.planilhas_reprovadas = response.data.dados.planilhas_reprovadas;
                    this.planilhas_validadas = response.data.dados.planilhas_validadas;

                    this.total_end_id = response.data.dados.qtd_endid_enviado;
                    this.end_id_validado = response.data.dados.qtd_endid_aprovado;
                    this.end_id_contestado = response.data.dados.qtd_endid_reprovado;
                    this.valor_validado = response.data.dados.valor_endid_aprovado;
                    this.valor_contestado = response.data.dados.valor_endid_reprovado;
                    this.valor_total = response.data.dados.valor_total;

                    this.medidor_proprio_tim = response.data.dados.medidor_proprio_tim;
                    this.total_medidor_proprio_tim = response.data.dados.total_medidor_proprio_tim;
                    this.site_em_aquisicao = response.data.dados.site_em_aquisicao;
                    this.valor_total_contestado = response.data.dados.valor_total_contestado;
                    this.total_site_em_aquisicao = response.data.dados.total_site_em_aquisicao;
                    this.total_outros = response.data.dados.total_outros;
                    this.outros = response.data.dados.outros;
                })
                .catch(() => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Ops!",
                        detail: "Chamar o T.I.",
                        life: 3000,
                    });
                });
        },

        // ------------------------------------------------------------------
        // Métodos auxiliares (opcionais)
        // ------------------------------------------------------------------
        createId() {
            let id = "";
            const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            for (let i = 0; i < 5; i++) {
                id += chars.charAt(Math.floor(Math.random() * chars.length));
            }
            return id;
        },
        exportCSV() {
            this.$refs.dt.exportCSV();
        }
    },
};
</script>

<style>
.p-datatable-thead,
.p-datatable-tbody {
    white-space: nowrap !important;
}
</style>
